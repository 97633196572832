<template>
    <v-card flat min-height="100%">
        <v-list class="pa-0 mt-3" min-height="20vh">
            <AccountItem
                    v-for="account in accounts"
                    :key="account.id"
                    :OS_RAH="account.OS_RAH"
                    :NVUL="account.NVUL"
                    :BUD="account.BUD"
                    :KVAR="account.KVAR"
                    :SAL_POCH="account.SAL_POCH"
            />
            <v-overlay :value="accountsLoading" color="black" :opacity="0.8" absolute z-index="2">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
            <v-overlay :value="!accounts.length" :opacity="1" absolute z-index="1">
                <div class="text-center" style="width: 100%">
                    <h1><v-icon color="grey lighten-2" size="50">mdi-account-cancel</v-icon></h1>
                    <p class="grey--text text--lighten-1">У користувача немає прив'язаних особових рахунків</p>
                </div>
            </v-overlay>
        </v-list>
    </v-card>
</template>

<script>
    import { mapActions} from 'vuex'
    import AccountItem from "./AccountItem";
    export default {
        props: ["id", "OS_RAH","NVUL","BUD","KVAR","SAL_POCH"],
        name: "UserAccounts",
        data() {
            return {
                items: {},
                accountsLoading: false,
                accounts: []
            }
        },
        components: {
            AccountItem
        },
        computed: {
        },
        methods: {
            ...mapActions('accounts', ['getAccounts']),
            getAllAccounts()
            {
                this.accountsLoading = true
                this.getAccounts(this.id)
                .then((resp) => {
                    this.accounts = resp.data.accounts
                    this.accountsLoading = false
                })
                .catch((error) => {
                    this.accounts = []
                    this.accountsLoading = false
                })
            }
        },
        watch:{
            $route: function(){
                this.getAllAccounts()
            }
        },
        mounted() {
            this.getAllAccounts()
        }
    }
</script>

<style scoped>

</style>