const BASE_ULR = 'https://zhitomir.billing.city/api/enterprises/admin/v1';
import axios from "axios";
function initialState () {
    const token = localStorage.getItem('access_token') || "";
    const admin = {
        name: localStorage.getItem('name') || "",
        email: localStorage.getItem('email') || "",
        id: localStorage.getItem('id') || null
    };
    const isAuth = token !== "";
    const loader = false;
    return {
        token,
        admin,
        isAuth,
        loader
    }
}

const getters = {
    admin(state)
    {
        return state.admin
    }
};

const actions = {
    async login(ctx, data)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/login',
                method: 'POST',
                data: data
            })
                .then((response) => {
                    ctx.commit('setToken', response.data.access_token)
                    ctx.commit('setAdmin', response.data.admin)
                    ctx.commit('setIsAuth', true)

                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    async logout(ctx)
    {
        ctx.commit('setToken', "")
        ctx.commit('setAdmin', {})
        ctx.commit('setIsAuth', '')
        localStorage.removeItem('access_token')
        localStorage.removeItem('name')
        localStorage.removeItem('email')
        localStorage.removeItem('id')
    }
};

const mutations = {
    setToken(state, token)
    {
        state.token = token;
        localStorage.setItem('access_token', state.token);
    },
    setAdmin(state, admin)
    {
        state.admin = admin;
        localStorage.setItem('name', state.admin.name);
        localStorage.setItem('email', state.admin.email);
        localStorage.setItem('id', state.admin.id);
    },
    setIsAuth(state)
    {
        state.isAuth = state.token !== ""
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
