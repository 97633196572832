const BASE_ULR = 'https://zhitomir.billing.city/api/enterprises/admin/v1';
import axios from "axios";
function initialState () {
   const accounts = [];
    const loader = false;
    return {
        accounts,
        loader
    }
}

const getters = {
    accounts(state)
    {
        return state.accounts
    }
};

const actions = {
    async getAccounts(ctx, id)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/users/' + id + '/accounts',
                method: 'GET',
            })
                .then((response) => {
                    ctx.commit('setAccounts', response.data.accounts)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

};

const mutations = {
    setAccounts(state, accounts)
    {
        state.accounts = accounts
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
