<template>
    <v-container>
        <v-row class="fill-height">
            <v-col max-height="100%" class="px-0 py-0 px-md-3 py-md-3">
                <v-card outlined class="card-content">
                    <v-card-title class="d-none d-md-block">
                        <v-col class="py-2">
                            Зворотній зв'язок
                        </v-col>
                    </v-card-title>
                    <div class="feedbacks-content" ref="feedbackList">
                        <v-list two-line subheader>
                            <FeedbackItem
                                    v-for="feedback in feedbacks"
                                    :key="feedback.id"
                                    :id="feedback.id"
                                    :ls="feedback.ls"
                                    :status="feedback.status"
                                    :title="feedback.title"
                                    :description="feedback.description"
                                    :created_at="feedback.created_at "
                            />
                            <div class="text-center pagination-wrapper">
                                <v-pagination
                                        v-model="pagination.currentPage"
                                        :length="pagination.lastPage"
                                        :total-visible="7"
                                        circle
                                        @input="onClickAlert"
                                ></v-pagination>
                            </div>
                            <v-overlay :value="feedbacksLoading" color="black" :opacity="0.8" absolute>
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </v-overlay>
                        </v-list>
                    </div>
                </v-card>
            </v-col>
            <router-view  @answer="getFeedbacks"></router-view>
        </v-row>
    </v-container>
</template>

<script>
    import FeedbackItem from "./components/Feedback/FeedbackItem";
    import {mapActions} from 'vuex'
    export default {
        props: ["id", 'ls', 'status', 'title', 'description', 'created_at'],
        name: "Feedback",
        data() {
            return {
                isShow: true,
                feedbacks: [],
                pagination: {
                    currentPage: 1,
                    total: 1,
                    perPage: 1,
                    lastPage: 1,
                },
                feedbacksLoading: false
            }
        },
        components: {
            FeedbackItem
        },
        computed: {},
        methods: {
            ...mapActions('feedback', ['getAllFeedback']),
            getFeedbacks()
            {
                this.$nextTick(() => {
                    this.$refs.feedbackList.scrollTop = 0;
                });
                this.feedbacksLoading = true
                this.getAllFeedback(this.pagination.currentPage)
                    .then((resp) => {
                        this.feedbacks = resp.data.feedbacks
                        this.pagination.currentPage = resp.data.pagination.currentPage;
                        this.pagination.total = resp.data.pagination.total;
                        this.pagination.perPage = resp.data.pagination.perPage;
                        this.pagination.lastPage = resp.data.pagination.lastPage;
                        this.feedbacksLoading = false
                    })
                    .catch(() => {
                        this.feedback = {}
                        this.feedbacksLoading = false
                    })
            },
            onClickAlert()
            {
                window.scrollTo(0,0);
            }
        },
        watch: {
            'pagination.currentPage': function () {
                this.getFeedbacks()
            }
        },

        mounted() {
            this.getFeedbacks();
        }
    }
</script>

<style scoped>
    .card-content{
        height: calc(100vh - 90px);
    }
    .feedbacks-content
    {
        height: calc(100vh - 180px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .content-row{
        position: absolute !important;
        top: 0;
        left: 0;
        height: 100%;
    }
    @media screen and (max-width: 960px) {
        .mobile-sidebar{
            position: absolute !important;
            top: 0;
            left: 0;
            height: 100%;
        }
        .card-content
        {
            height: calc(100vh - 56px);
        }
        .feedbacks-content
        {
            height: calc(100vh - 56px);
        }
    }
</style>