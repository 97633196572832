<template>
    <v-list-item class="feedback-item my-1 py-2" :class="status>0 ? 'green-item' : 'red-item'" :color="status>0 ? 'green' : 'red'" :to="{ name: 'Feedback', params: { id } }">
        <v-list-item-content class="py-0">
            <v-row>
                <v-col cols="8" md="9" lg="10" class="pr-0 pb-0">
                    <h4 class="">{{title}}</h4>
                    <h5 class="font-weight-regular grey--text text--darken-1">Звернення №{{id}}</h5>
                </v-col>
                <v-col cols="4" md="3" lg="2" class="pb-0 text-right">
                    <h4 class="mb-0 font-weight-regular">{{time | moment("h:mm")}} </h4>
                    <h5 class="mb-0 font-weight-regular grey--text text--darken-1">{{time | moment("D.MM.YYYY")}}</h5>
                </v-col>
                <v-col cols=12>
                    <p class="mt-2 mb-0">{{getShortText(description)}}</p>
                </v-col>
            </v-row>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
    import Vue from 'vue'
    const moment = require('moment');
    require('moment/locale/uk');
    Vue.use(require('vue-moment'), {
        moment
    });
    export default {
        props: ['id', 'status', 'description', 'title', 'created_at'],
        name: "FeedbackItem",
        data() {
            return {}
        },
        components: {},
        computed: {
            time(){
                return this.created_at;//.split(' ')[0];
            },
            date(){
                return this.created_at;//.split(' ')[1];
            }
        },
        methods: {
            getShortText(description)
            {
                if(description.length > 300)
                    return description.substring(0,299)+"...";
                return description;
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
    .feedback-item
    {
        box-sizing: border-box !important;
        -moz-box-sizing: border-box !important;
        -webkit-box-sizing: border-box !important;
        border-left-width: 3px !important;
        border-left-style: solid !important;
        border-left-color: #CCC;
    }
    .green-item
    {
        border-left-color: #00C853 !important;
    }
    .red-item
    {
        border-left-color: #F44336 !important;
    }
</style>