import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import Users from "../views/Users";
import Feedbacks from "../views/Feedbacks";
import Notifications from "../views/Notifications";
import Statistics from "../views/Statistics";
import User from "../views/components/Users/User";
import UserAccounts from "../views/components/Users/UserAccounts";
import UserNotifications from "../views/components/Users/UserNotifications";
import UserPayments from "../views/components/Users/UserPayments";
import Feedback from '../views/components/Feedback/Feedback'

import guest from "./middleware/guest";
import auth from "./middleware/auth";
import middlewarePipeline from "./middlewarePipeline";

Vue.use(VueRouter);

const routes = [
  { path: '/',
    redirect: { name: 'Login' }
  },
  {
    path: '/login',
    name: 'Login',
    meta:{layout: 'auth', title: 'Вхід', middleware: [guest]},
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta:{layout: 'main', title: 'Головна', middleware: [auth]},
    component: Dashboard
  },
  {
    path: '/users',
    name: 'Users',
    meta:{layout: 'main', title: 'Користувачі', middleware: [auth]},
    component: Users,
    children: [
      {
        path: ':id',
        component: User,
        name: 'User',
        meta: {layout: 'main', title: "Користувачі", middleware: [auth]},
        props: true,
        children: [
          {
            path: '/',
            redirect: {name: 'UserAccounts'}
          },
          {
            path: 'accounts',
            name: 'UserAccounts',
            component: UserAccounts,
            meta: {layout: 'main', title: "Користувачі", middleware: [auth]},
            props: true
          },
          {
            path: 'notifications',
            name: 'UserNotifications',
            component: UserNotifications,
            meta: {layout: 'main', title: "Користувачі", middleware: [auth]},
            props: true
          },
          {
            path: 'payments',
            name: 'UserPayments',
            component: UserPayments,
            meta: {layout: 'main', title: "Пользователи", middleware: [auth]},
            props: true
          }
        ]
      }
    ],
  },
  {
    path: '/feedbacks',
    name: 'Feedbacks',
    meta:{layout: 'main', title: 'Зворотній зв\'язок', middleware: [auth]},
    component: Feedbacks,
    children: [
      {
        path: ':id',
        name: 'Feedback',
        meta:{layout: 'main', title: 'Зворотній зв\'язок', middleware: [auth]},
        component: Feedback,
        props: true
      }
    ]
  },
  {
    path: '/notifications',
    name: 'Notifications',
    meta:{layout: 'main', title: 'Повідомлення', middleware: [auth]},
    component: Notifications
  },
  {
    path: '/statistics',
    name: 'Statistics',
    meta:{layout: 'main', title: 'Статистика', middleware: [auth]},
    component: Statistics
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
  return next()
}
if (!to.meta.title)
  document.title = "Житомир Мобайл";
else
  document.title = to.meta.title+" | Житомир Мобайл";
const middleware = to.meta.middleware;
const context = {
  to,
  from,
  next,
  store
};
return middleware[0]({
  ...context,
  next: middlewarePipeline(context, middleware, 1)
})
});

export default router
