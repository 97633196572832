const BASE_ULR = 'https://zhitomir.billing.city/api/enterprises/admin/v1';
import axios from "axios";
function initialState () {
    const users = [];
    const user = {};
    const loader = false;
    return {
        users,
        loader,
        user,
    }
}

const getters = {
    users(state)
    {
        return state.users
    },
    user(state)
    {
        return state.user
    }
};

const actions = {
    async getAllUsers(ctx, {page = 1, searchField = ''})
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/users?page=' + page + '&search=' + searchField,
                method: 'GET',
                // data: searchField
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async getOneUser(ctx, id)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/users/' + id,
                method: 'GET',
            })
                .then((resp) => {
                    // ctx.commit('setUser', resp.data.user)
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    // async searchUser(ctx, searchField= '')
    // {
    //     return new Promise((resolve, reject) => {
    //         axios({
    //             url: BASE_ULR + '/users?search=' + searchField,
    //             method: 'GET',
    //         })
    //             .then((resp) => {
    //             resolve(resp)
    //         })
    //             .catch((error) => {
    //                 reject(error)
    //             })
    //     })
    // }

};

const mutations = {
    setUsers(state, users)
    {
        state.users = users
    },
    setUser(state, user)
    {
        state.user = user
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
