<template>
    <v-col class="mobile-sidebar px-0 py-0 pr-md-3 py-md-3" transition="slide-x-reverse-transition" cols=12 md=6 lg=5>
        <v-card height="100%" outlined class="py-2 user-card">
            <v-row>
                <v-col cols=11 class="px-8 text-left pb-3">
                    <v-avatar color="primary" size="40" class="float-left mr-3">
                        <span class="white--text">Користувач</span>
                    </v-avatar>
                    <h4 class="mb-0">Користувач №{{feedback.user.id}}</h4>
                    <h5 class="mb-0 font-weight-regular">ID: {{feedback.user.id}}</h5>
                </v-col>
            </v-row>
            <div class="feedback-content px-5">
                <div class="answer" v-if="bottomNav === 'answer'">
                    <p class="mb-0 mt-2">LS: {{feedback.ls}}</p>
                    <p class="mb-0 mt-2"> <v-icon>mdi-email</v-icon> {{feedback.user.email}}</p>
                    <p class="mb-0 mt-1"> <v-icon>mdi-phone</v-icon> {{feedback.user.phone}}</p>
                    <v-divider class="mt-3"></v-divider>
                    <div>
                        <v-row class="mt-3">
                            <v-col cols="8" md="9" class="pr-0 pb-0">
                                <h4>{{feedback.title}}</h4>
                                <h5 class="font-weight-regular grey--text text--darken-1">Звернення №{{feedback.id}}</h5>
                            </v-col>
                            <v-col cols="4" md="3" class="pb-0 text-right">
                                <h4 class="mb-0 font-weight-regular">{{feedback.created_at | moment("h:mm")}}</h4>
                                <h5 class="mb-0 font-weight-regular grey--text text--darken-1">{{feedback.created_at | moment("D.MM.YYYY")}}</h5>
                            </v-col>
                            <v-col cols=12>
                                <h4 class="mb-3 mt-2 font-weight-regular">{{feedback.description}}</h4>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="!feedback.status || answerIsEdit">
                        <v-textarea
                                class="mt-1 mb-3"
                                hide-details
                                name="input-7-1"
                                label="Відповідь"
                                v-model="feedback.answer"
                        ></v-textarea>
                        <v-btn class="px-3 mb-3" color="primary" @click="answer" block>{{ feedback.status ? 'Зберегти' : 'Відповісти' }}</v-btn>
                    </div>
                    <div v-if="feedback.status && !answerIsEdit">
                        <v-divider></v-divider>
                        <v-list-item-title class="mt-3">
                            <h4 class="float-left">Відповідь</h4>
                            <v-btn class="float-right" icon color="primary" @click="answerIsEdit = true"> <v-icon>mdi-pencil</v-icon> </v-btn>
                        </v-list-item-title>
                        <p class="mt-2 mb-0 font-weight-regular">{{feedback.answer}}</p>
                        <h5 class="mb-3 mt-3 font-weight-regular"><v-icon small>mdi-clock</v-icon> {{feedback.updated_at | moment("h:mm")}} <span class="grey--text">{{feedback.updated_at | moment("D.MM.YYYY")}}</span></h5>
                    </div>
                </div>
                <div class="history" v-if="bottomNav === 'history'">
                    <h4 class="mt-3 mb-3">Історія звернень</h4>
                    <v-list two-line subheader>
                        <v-list-item
                                v-for="item in history"
                                :key="item.id"
                                class="feedback-item my-1 py-2"
                                color="grey">
                            <v-list-item-content class="py-0">
                                <v-list-item-title >
                                    <h5 class="float-left">{{item.title}}</h5>
                                    <h5 class="mb-0 float-right font-weight-regular">{{item.created_at | moment("h:mm")}} <span class="grey--text">{{item.created_at | moment("D.MM.YYYY")}}</span></h5>
                                </v-list-item-title>
                                <h5 class="mt-2 mb-3 font-weight-regular">{{item.description}}</h5>
                                <v-list-item-title v-if="item.status">
                                    <h5 class="float-left">Відповідь</h5>
                                    <h5 class="mb-0 float-right font-weight-regular">{{item.updated_at | moment("h:mm")}} <span class="grey--text">{{item.updated_at | moment("D.MM.YYYY")}}</span></h5>
                                </v-list-item-title>
                                <h5 v-if="item.status" class="mt-2 mb-0 font-weight-regular">{{item.answer}}</h5>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
            </div>
            <v-bottom-navigation absolute color="primary" bottom="0" v-model="bottomNav" grow>
                <v-btn value="answer">
                    <span>Відповідь</span>
                    <v-icon>mdi-account-multiple</v-icon>
                </v-btn>

                <v-btn value="history">
                    <span>Історія</span>
                    <v-icon>mdi-mail</v-icon>
                </v-btn>

            </v-bottom-navigation>
            <v-btn color="grey" icon absolute top right @click="onClickCloseButton"> <v-icon>mdi-close</v-icon> </v-btn>
            <v-overlay :value="feedbackLoading" :opacity="1" absolute>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-col>
</template>

<script>
    import {mapActions} from 'vuex'
    import Vue from 'vue'
    const moment = require('moment');
    require('moment/locale/uk');
    Vue.use(require('vue-moment'), {
        moment
    });
    export default {
        props: ['id'],
        name: "Feedback",
        data() {
            return {
                bottomNav: "answer",
                feedbackLoading: false,
                answerIsEdit: false,
                feedback: {
                    id: 0,
                    ls: 0,
                    title: "",
                    description: "",
                    answer: "",
                    status: 0,
                    created_at: "",
                    updated_at: "",
                    user: {
                        id: 0,
                        phone: "",
                        email: ""
                    }
                },
                history: []
            }
        },
        components: {},
        computed: {
             avatarText:{
                get() {
                  return  this.feedback.user.id
                }
            }
        },
        methods: {
            ...mapActions('feedback', [
                'getAllFeedback',
                'getFeedbackHistory',
                'sendFeedbackAnswer'
            ]),
            ...mapActions('users', ['getOneUser']),
            onClickCloseButton(){
                this.$router.push({ name: 'Feedbacks'});
            },

            getFeedback()
            {
                this.getFeedbackHistory(this.id)
                .then((resp) => {
                    this.feedback.id = resp.data.feedback.id
                    this.feedback.ls = resp.data.feedback.ls
                    this.feedback.title = resp.data.feedback.title
                    this.feedback.description = resp.data.feedback.description
                    this.feedback.answer = resp.data.feedback.answer
                    this.feedback.status = resp.data.feedback.status
                    this.feedback.created_at = resp.data.feedback.created_at
                    this.feedback.updated_at = resp.data.feedback.updated_at
                    this.feedback.user.id = resp.data.feedback.user.id
//                    this.feedback.user.fio = resp.data.feedback.user.fio
                    this.feedback.user.phone = resp.data.feedback.user.phone
                    this.feedback.user.email = resp.data.feedback.user.email
                    this.history = resp.data.history
                })
            },
            answer()
            {
                // alert(this.feedback.answer)
                // this.feedbackLoading = true
                const data = {
                    answer: this.feedback.answer
                }
                this.sendFeedbackAnswer({id: this.id, data: data})
                .then(() => {
                    // this.showMessage({ text: "Ответ успешно "+(this.feedback.status?"сохранен!":"отправлен!"), type: "success"})
                    this.getFeedback()
                    this.$emit('answer')
                    this.answerIsEdit = false;
                    this.feedbackLoading = false;
                })
                .catch(() => {
                    this.answerIsEdit = false;
                    this.feedbackLoading = false;
                })
            },

        },
        watch: {
            id: function () {
                this.getFeedback()
            }
        },
        mounted() {
            this.getFeedback()
        }
    }
</script>

<style scoped>
    .feedback-content
    {
        height: calc(100vh - 220px);
        overflow-y: auto;
    }
    .user-card
    {
        overflow: hidden;
    }
    .button-send
    {
        width: 100%;
        position: absolute;
        bottom: 0;
        padding: 17px;
    }
    @media screen and (max-width: 960px) {
        .feedback-content
        {
            height: calc(100vh - 210px);
            overflow-y: auto;
        }
    }
    .green-item
    {
        border-left-color: #00C853 !important;
    }
    .feedback-item
    {
        box-sizing: border-box !important;
        -moz-box-sizing: border-box !important;
        -webkit-box-sizing: border-box !important;
        border-left-width: 3px !important;
        border-left-style: solid !important;
        border-left-color: #CCC;
    }
</style>