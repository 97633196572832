const BASE_ULR = 'https://zhitomir.billing.city/api/enterprises/admin/v1';
import axios from "axios";
function initialState () {
    const payments = [];
    const loader = false;
    return {
        payments,
        loader
    }
}

const getters = {
    payments(state)
    {
        return state.payments
    }
};

const actions = {
    async getAllPayments(ctx, id)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/users/' + id + '/payments',
                method: 'GET',
            })
                .then((response) => {
                    ctx.commit('setPayments', response.data.payments)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

};

const mutations = {
    setPayments(state, payments)
    {
        state.payments = payments
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
