<template>
    <v-container>
        <v-row>
            <v-col max-height="100%" class="px-0 py-0 px-md-3 py-md-3">
                <v-card outlined class="card-content">
                    <v-card-title>
                        <v-col class="py-0 d-none d-md-block">
                            Користувачі
                        </v-col>
                        <v-col class="pa-0">
                            <v-text-field
                                    solo
                                    background-color="dark"
                                    outlined
                                    flat
                                    v-model="searchField"
                                    hide-details
                                    label="Пошук"
                                    clearable
                                    prepend-inner-icon="mdi-magnify"/>
                        </v-col>
                    </v-card-title>
                    <div class="scroll-overflow" ref="usersList">
                        <v-card flat height="100%">
                            <v-list two-line subheader>
                                <!-- <v-subheader inset>Folders</v-subheader> -->
                                <UserItem
                                        v-for="user in users"
                                        :key="user.id"
                                        :id="user.id"
                                        :name="user.id"
                                        :phone="user.phone"/>
                                <div class="text-center pagination-wrapper">
                                    <v-pagination v-model="pagination.currentPage" :length="pagination.lastPage" :total-visible="9" circle></v-pagination>
                                </div>
                                <v-overlay :value="usersLoading" :opacity="0.8" absolute>
                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                </v-overlay>
                            </v-list>
                        </v-card>
                    </div>
                </v-card>
            </v-col>
            <router-view></router-view>
        </v-row>
    </v-container>
</template>

<script>
    import UserItem from "./components/Users/UserItem";
    import {mapActions, mapGetters} from 'vuex'
    export default {
        name: "Users",
        data() {
            return {
                isShow: true,
                searchField: "",
                users: [],
                pagination: {
                    currentPage: 1,
                    total: 1,
                    perPage: 1,
                    lastPage: 1,
                },
                usersLoading: false,
            }
        },
        components: {
            UserItem
        },
        computed: {

        },
        methods: {
            ...mapActions('users', ['getAllUsers']),
            getUsers()
            {
                this.$nextTick(() => {
                    this.$refs.usersList.scrollTop = 0;
                });
                this.usersLoading = true
                this.getAllUsers({
                    page: this.pagination.currentPage,
                    searchField: this.searchField
                })
                .then((resp) => {
                    this.users = resp.data.users
                    this.pagination = resp.data.pagination
                    this.usersLoading = false
                })
            },

        },
        watch: {
            'pagination.currentPage': function () {
                this.getUsers()
            },
            searchField(){
                this.searchField = this.searchField == null ? "" : this.searchField;
                this.getUsers()
            }
        },
        mounted() {
            this.getUsers()
        }
    }
</script>

<style scoped>
    .pagination-wrapper
    {
        width: 100%;
    }
    .card-content{
        height: calc(100vh - 90px);
    }
    .scroll-overflow
    {
        height: calc(100vh - 180px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .content-row{
        position: absolute !important;
        top: 0;
        left: 0;
        height: 100%;
    }
    @media screen and (max-width: 960px) {
        .mobile-sidebar{
            position: absolute !important;
            top: 0;
            left: 0;
            height: 100%;
        }
        .card-content
        {
            height: calc(100vh - 56px);
        }
        .scroll-overflow
        {
            height: calc(100vh - 136px);
        }
    }

</style>