import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth";
import users from "./modules/users";
import accounts from "./modules/accounts";
import notifications from "./modules/notifications";
import payments from "./modules/payments";
import feedback from "./modules/feedback";
import dashboard from "./modules/dashboard";
import statistics from "./modules/statistics";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    users,
    accounts,
    notifications,
    payments,
    feedback,
    dashboard,
    statistics
  }
})
