const BASE_ULR = 'https://zhitomir.billing.city/api/enterprises/admin/v1';
import axios from "axios";
function initialState () {

    return {

    }
}

const getters = {

};

const actions = {
    async commonStats(ctx, page = 1)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/statistics',
                method: 'GET',
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async getFullStat(ctx, data)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/statistics',
                method: 'POST',
                data: data
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

};

const mutations = {

};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
