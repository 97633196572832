<template>
    <v-container>
        <v-row>
            <v-col class="pa-0 pa-md-3" cols=12 md=6 lg=6 :class="bottomNav != 'sheduled' ? 'd-none d-md-block' : 'd-block'">
                <v-card outlined="" class="pa-5 pa-md-7">
                    <v-card-title class="pa-0 mb-3 d-none d-md-block">Заплановані повідомлення</v-card-title>
                    <v-row>
                        <v-col cols="10" lg="11" class="pr-8">
                            <v-text-field
                                    prepend-inner-icon="mdi-magnify"
                                    v-model.lazy="searchField"
                                    label="Поиск"
                                    hide-details
                                    rounded
                                    single-line
                                    filled
                                    clearable
                                    dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2" lg="1" class="">
                            <v-btn @click="onClickAdd" class="mx-2 float-right" small fab depressed dark color="indigo">
                                <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="notification-sheduled-content">
                        <v-col cols="12">
                            <PushItem  v-for="item in sheduledNotifications"
                                       :key="item.id"
                                       :id="item.id"
                                       :title="item.title"
                                       :text="item.text"
                                       :audience="item.audience"
                                       :date="item.date"
                                       :time="item.time"
                                       @edit="onClickEdit"
                                       @delete="onClickDelete"/>
                            <h4 class="text-center grey--text font-weight-light" v-if="sheduledNotifications.length < 1">Немає запланованих повідомлень!</h4>
                        </v-col>
                        <v-col v-if="currentPage != lastPage" cols="12">
                            <v-btn :loading="sheduledNotifications" :disabled="sheduledNotifications" block color="secondary" @click="onClickLoad">
                                Завантажити ще
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-overlay :value="sheduledLoading" color="white" :opacity="0.8" absolute>
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-col>
            <v-col class="pa-0 pa-md-3" cols=12 md=6 lg=6 :class="bottomNav != 'sent' ? 'd-none d-md-block' : 'd-block'">
                <v-card outlined="" class="pa-5 pa-md-7">
                    <v-card-title class="pa-0 mb-3 d-none d-md-block">Відправлені повідомлення</v-card-title>
                    <v-row class="notification-sent-content">
                        <v-col cols="12">
                            <PushSentItem  v-for="item in sentNotifications"
                                           :key="item.id"
                                           :id="item.id"
                                           :title="item.title"
                                           :text="item.text"
                                           :audience="item.audience"
                                           :date="item.date"
                                           :time="item.time"
                                           :success-count="item.success_count"
                                           :failed-count="item.failed_count"
                                           :curr-token="item.curr_token"
                                           :count-token="item.count_token"
                                           :devices-count="item.devices_count"
                                           :status="item.status"
                            />
                            <h4 class="text-center grey--text font-weight-light" v-if="sentNotifications.length < 1">Немає відправлених повідомлень!</h4>
                        </v-col>
                    </v-row>
                    <v-overlay :value="sentLoading" color="white" :opacity="0.8" absolute>
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-col>
        </v-row>
        <v-bottom-navigation absolute color="primary" class="d-md-none" bottom="0" v-model="bottomNav" grow>
            <v-btn value="sheduled">
                <span>Заплановані</span>
                <v-icon>mdi-clock-fast</v-icon>
            </v-btn>

            <v-btn value="sent">
                <span>Відправлені</span>
                <v-icon>mdi-message-text</v-icon>
            </v-btn>

        </v-bottom-navigation>
        <PushModal ref="PushModal" @alert="showAlert" @update="getSheduledNotification(1,false)"/>
        <DeleteModal ref="DeleteModal" @alert="showAlert" @update="getSheduledNotification(1,false)"/>
    </v-container>
</template>

<script>
    import DeleteModal from "./components/Notifications/DeleteModal";
    import PushItem from "./components/Notifications/PushItem";
    import PushSentItem from "./components/Notifications/PushSentItem";
    import PushModal from "./components/Notifications/PushModal";
    import {mapActions} from 'vuex'
    export default {
        name: "Notifications",
        data() {
            return {
                bottomNav: 'sheduled',
                sheduledLoading: false,
                sentLoading: false,
                sheduledNotifications: [],
                sentNotifications: [],
                currentPage: 1,
                lastPage: 1,
                searchField: ''
            }
        },
        components: {
            DeleteModal,
            PushItem,
            PushSentItem,
            PushModal
        },
        computed: {},
        methods: {
            ...mapActions('notifications', [
                'showMessage',
                'getSheduledNotifications',
                'getSentNotifications'
            ]),
            onClickAdd()
            {
                this.$refs.PushModal.show(false)
            },

            onClickEdit(params)
            {
                this.$refs.PushModal.show(true, params)
            },

            onClickDelete(params)
            {
                this.$refs.DeleteModal.show(true, params)
            },

            onClickLoad()
            {
                this.getPushes(this.currentPage + 1)
            },
            showAlert(text, type)
            {
                this.showMessage({text, type})
            },
            getSheduledNotification(page = 1, concat = true)
            {
                this.sheduledLoading = true;
                this.getSheduledNotifications(page, this.searchField)
                .then((resp) => {
                    console.log(resp.data)
                    if (concat)
                        this.sheduledNotifications = this.sheduledNotifications.concat(resp.data.data)
                    else
                        this.sheduledNotifications = resp.data.data
                    this.sheduledLoading = false
                })
                .catch((error) => {
                    console.log(error.data)
                    this.sheduledLoading = false
                })
            },
            getSentNotification(loading)
            {
                this.sentLoading = loading
                this.getSentNotifications()
                .then((resp) => {
                    console.log(resp.data)
                    this.sentNotifications = resp.data
                    this.sentLoading = false
                    setTimeout(() => {
                        this.getSentNotification(false)
                    }, 5000)
                })
                .catch((error) => {
                    console.log(error.data)
                    this.sentLoading = false
                })
            }
        },
        watch: {
            searchField: function (value) {
                this.searchField = this.searchField == null ? "" : this.searchField;
                this.getSheduledNotification(1, false);
            }
        },
        mounted() {
            this.getSheduledNotification();
            this.getSentNotification(true);
        }
    }
</script>

<style scoped>
    .notification-sheduled-content, .notification-sent-content
    {
        overflow-x: hidden;
        overflow-y: auto;
    }
    .notification-sheduled-content
    {
        height: calc(100vh - 250px);
    }
    .notification-sent-content
    {
        height: calc(100vh - 186px);
    }
    @media screen and (max-width: 960px) {
        .notification-sheduled-content
        {
            height: calc(100vh - 210px);
        }
        .notification-sent-content
        {
            height: calc(100vh - 146px);
        }
    }
</style>