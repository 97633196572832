<template>
    <v-list-item class="px-6">
        <v-list-item-content class="">
            <v-list-item-title>{{title}}</v-list-item-title>
            <v-list-item-subtitle>{{text}}</v-list-item-subtitle>
            <h5 class="mt-1 font-weight-regular grey--text text--darken-1"><v-icon small class="mr-1">mdi-calendar</v-icon>{{date}}</h5>
        </v-list-item-content>

        <v-list-item-group>

        </v-list-item-group>
    </v-list-item>
</template>

<script>
    export default {
        props: ["id", "title","text","date"],
        name: "NotificationItem",
        data() {
            return {}
        },
        components: {},
        computed: {},
        methods: {},
        mounted() {
        }
    }
</script>

<style scoped>

</style>