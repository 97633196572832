<template>
    <v-card outlined>
        <v-card-title class="">
            <v-col class="py-2">
                <h5>Нові користувачі</h5>
            </v-col>
        </v-card-title>
        <div class="dashboard-users-content">
            <v-list two-line subheader>
                <UserItem v-for="user in users" :key="user.id" :id="user.id" :phone="user.phone"/>
            </v-list>
        </div>
        <v-overlay :value="usersLoading" color="white" :opacity="0.8" absolute>
            <v-progress-circular
                    indeterminate
                    color="primary"
            ></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script>
    import UserItem from "../Users/UserItem";
    import {mapActions} from 'vuex'
    export default {
        name: "Users",
        data() {
            return {
                users: [],
                usersLoading: false
            }
        },
        components: {
            UserItem
        },
        computed: {},
        methods: {
            ...mapActions('dashboard', ['newUsers']),
            getUsers()
            {
                this.usersLoading = true
                this.newUsers()
                .then((resp) => {
                    this.users = resp.data.users
                    this.usersLoading = false
                })
                .catch((error) => {
                    this.usersLoading = false
                })
            }
        },
        mounted() {
            this.getUsers()
        }
    }
</script>

<style scoped>
    .dashboard-users-content
    {
        height: calc(100vh - 300px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    @media screen and (max-width: 1264px) {
        .dashboard-users-content
        {
            height: calc(100vh - 440px);
        }
    }
    @media screen and (max-width: 960px) {
        .dashboard-users-content
        {
            height: auto;

        }
    }
</style>