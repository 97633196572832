<template>
    <v-card class="pb-3 pa-0" flat>
        <v-divider class="mb-2"></v-divider>
        <v-card-title class="px-3">
            <v-row>
                <v-col cols="8" class="py-0 px-0">
                    <h5 class="mb-0">{{title}}</h5>
                </v-col>
                <v-col cols="4" class="py-0 px-0">
                    <v-chip class="float-right" small :color="audienceColor" text-color="white">
                        {{audienceLabel}}
                    </v-chip>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="px-0">
            {{text}}
        </v-card-text>
        <v-card-actions class="px-0 mb-0">
            <v-icon class="mr-2">mdi-calendar</v-icon> {{date}}
            <v-icon class="mx-2">mdi-clock</v-icon> {{time}}
            <v-spacer></v-spacer>

            <v-btn icon @click="onClickEdit">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn icon @click="onClickDelete">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        props: ['id', 'title', 'text', 'audience','time','date'],
        name: "PushItem",
        data() {
            return {}
        },
        components: {},
        computed: {
            audienceLabel()
            {
                const labels = ['Всі', 'Боржники'];
                return labels[this.audience]
            }
        },
        methods: {
            onClickEdit()
            {
                this.$emit('edit', {
                    id: this.id,
                    title: this.title,
                    text: this.text,
                    audience: this.audience,
                    date: this.date,
                    time: this.time,
                })
            },
            onClickDelete()
            {
                this.$emit('delete', {title: this.title, id: this.id})
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>