<template>
    <v-list-item class="px-6">
        <v-list-item-icon>
            <v-icon color="green">mdi-currency-usd-circle</v-icon>
        </v-list-item-icon>

        <v-list-item-content class="">
            <v-list-item-title>{{amount}} грн</v-list-item-title>
            <v-list-item-subtitle></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-group>
            <small class="grey--text text--lighten-1">{{date}} року</small>
        </v-list-item-group>
    </v-list-item>
</template>

<script>
    export default {
        props: ["id","amount","date"],
        name: "PaymentItem",
        data() {
            return {}
        },
        components: {},
        computed: {},
        methods: {},
        mounted() {
        }
    }
</script>

<style scoped>

</style>