<template>
    <v-container>
        <v-row>
            <v-col cols=12 class="pa-0 pa-md-3">
                <div class="statistics-content">
                    <v-row>
                        <v-col class="px-0 py-0 px-md-3" cols=12 md=4 lg=3>
                            <v-card outlined class="pa-7">
                                <v-card-title class="pa-0 mb-3">Статистика</v-card-title>
                                <v-row>
                                    <v-col cols=12>
                                        <v-select
                                                :items="commonStatistics"
                                                item-text="name"
                                                item-value="type"
                                                v-model="selectedType"
                                                solo
                                                outlined
                                                flat
                                                hide-details
                                                @change="getFullStatistics"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols=12 class="py-0">
                                        <v-list-item three-line class="px-0">
                                            <v-list-item-content>

                                                <v-list-item-subtitle
                                                        v-if="commonStatistics[selectedType].percent != 0"
                                                        class=" mb-0"
                                                        :class="commonStatistics[selectedType].percent > 0 ? 'green--text': 'red--text'"
                                                ><v-icon small
                                                         :color="commonStatistics[selectedType].percent > 0 ? 'green': 'red'">
                                                    {{commonStatistics[selectedType].percent > 0 ? 'mdi-arrow-top-right': 'mdi-arrow-bottom-right'}}</v-icon>
                                                    {{Math.abs(commonStatistics[selectedType].percent)}} %
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle v-else class="grey--text mb-0">0 %
                                                </v-list-item-subtitle>
                                                <v-list-item-title class="headline mb-1"> <h3>{{commonStatistics[selectedType].value}}</h3> </v-list-item-title>
                                            </v-list-item-content>
                                            <v-icon class="" size="60" :color="'grey'">{{commonStatistics[selectedType].icon}}</v-icon>
                                        </v-list-item>
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-left px-0">Дата</th>
                                                    <th class="text-right px-0">Кількість</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in statsReverse" :key="item.name">
                                                    <td class="px-0">{{ item.date }}</td>
                                                    <td class="text-right px-0">{{ item.value }}</td>
                                                </tr>
                                                </tbody>
                                                <v-overlay :value="fullStatisticsLoading" color="white" :opacity="1" absolute>
                                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                                </v-overlay>
                                            </template>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                                <v-overlay :value="commonStatisticsLoading" color="white" :opacity="1" absolute>
                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                </v-overlay>
                            </v-card>
                        </v-col>
                        <v-col class="px-0 py-0 pr-md-3" cols=12 md=8 lg=9>
                            <v-card outlined class="pa-md-3">
                                <Chart :data="chartData" :labels="labels"/>
                                <v-overlay :value="fullStatisticsLoading" color="white" :opacity="0.95" absolute>
                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                </v-overlay>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Chart from "./components/Statistics/Chart";
    import {mapActions} from 'vuex'
    export default {
        name: "Statistics",
        data() {
            return {
                selectedType: 0,
                commonStatistics: [
                    {type: 0, name: 'Користувачі'},
                    {type: 0, name: 'Користувачі з О/Р'},
                    {type: 0, name: 'Користувачі без О/Р'},
                    {type: 0, name: 'Платники'},
                    {type: 0, name: 'Неплатники'}
                ],
                fullStatistics: [],
                commonStatisticsLoading: false,
                fullStatisticsLoading: false
            }
        },
        components: {
            Chart
        },
        computed: {
            labels()
            {
                return this.fullStatistics.map(stat => stat.date)
            },
            chartData()
            {
                return this.fullStatistics.map(stat => stat.value)
            },
            statsReverse()
            {
                return this.fullStatistics.slice().reverse()
            }
        },
        methods: {
            ...mapActions('statistics', ['commonStats', 'getFullStat']),
            getCommonStatistics()
            {
                this.commonStatisticsLoading = true
                this.commonStats()
                .then((resp) => {
                    this.commonStatistics = resp.data.data
                    this.commonStatisticsLoading = false
                    this.getFullStatistics()
                })
                .catch((error) => {
                    this.commonStatisticsLoading = false
                })
            },
            getFullStatistics()
            {
                const data = {
                    type: this.selectedType
                }
                this.fullStatisticsLoading = true
                this.getFullStat(data)
                .then((resp) => {
                    this.fullStatistics = resp.data.data
                    this.fullStatisticsLoading = false
                })
                .catch((error) => {
                    this.fullStatisticsLoading = false
                })
            }
        },
        mounted() {
            this.getCommonStatistics()
        }
    }
</script>

<style scoped>
    .statistics-content
    {
        width:100%;
        height: calc(100vh - 90px);
        overflow-x: hidden;
        overflow-y: auto;
    }
    @media screen and (max-width: 960px) {
        .statistics-content
        {
            height: calc(100vh - 56px);
        }
    }
</style>