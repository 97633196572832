<template>
    <v-container>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="400">
                <v-card>
                    <v-card-title class="headline"><h6>Видалити повідомлення</h6></v-card-title>
                    <v-card-text>Ви дійсно хочете видалити PUSH повідомлення?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="grey darken-1" text @click="dialog = false">Відміна</v-btn>
                        <v-btn color="error" text @click="deletePush">Видалити</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
    import {mapActions} from 'vuex'
    export default {
        name: "DeleteModal",
        data() {
            return {
                dialog: false,
                push: {
                    id: 0
                }
            }
        },
        components: {},
        computed: {
            baseUrl()
            {
                return this.$route.meta.baseUrl
            }
        },
        methods: {
            ...mapActions('notifications', ['delete']),
            show(isEdit, push)
            {
                this.push = push
                this.dialog = true
            },

            showAlert(text, color)
            {
                this.$emit('alert', text, color)
            },

            deletePush()
            {
                this.delete(this.push.id)
                .then((resp) => {
                    this.$emit('update')
                    this.showAlert('Успішно видалено', 'success')
                    console.log(resp.data)
                    this.dialog = false
                })
                .catch((error) => {
                    console.log(error.response.data)
                })
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>