<template>
    <div>
        <v-container>
            <v-row class="dashboard-content">
                <v-col class="pa-0 pa-md-3" cols="12" md="6" lg="3" sm="12">
                    <v-card class="mx-auto" outlined >
                        <div class="overline tab-name px-4 pt-3">Користувачі</div>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-subtitle class="mb-0">
                                </v-list-item-subtitle>
                                <v-list-item-title class="headline mb-1"> <h3>{{users}}</h3> </v-list-item-title>
                            </v-list-item-content>
                            <v-icon class="" size="60" color="primary">mdi-account-multiple</v-icon>
                        </v-list-item>
                    </v-card>
                </v-col>

                <v-col class="pa-0 pa-md-3"  cols="12" md="6" lg="3" sm="12">
                    <v-card class="mx-auto" outlined >
                        <div class="overline tab-name px-4 pt-3">Повідомлення</div>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-subtitle class="mb-0">
                                </v-list-item-subtitle>
                                <v-list-item-title class="headline mb-1"> <h3>{{notifications}}</h3> </v-list-item-title>
                            </v-list-item-content>
                            <v-icon class="" size="60" color="primary">mdi-email-multiple</v-icon>
                        </v-list-item>
                    </v-card>
                </v-col>

                <v-col class="pa-0 pa-md-3"  cols="12" md="6" lg="3" sm="12">
                    <v-card class="mx-auto" outlined >
                        <div class="overline tab-name px-4 pt-3">Звернення</div>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-subtitle class="mb-0">
                                </v-list-item-subtitle>
                                <v-list-item-title class="headline mb-1"> <h3>{{feedbacks}}</h3> </v-list-item-title>
                            </v-list-item-content>
                            <v-icon class="" size="60" color="primary">mdi-frequently-asked-questions</v-icon>
                        </v-list-item>
                    </v-card>
                </v-col>

                <v-col class="pa-0 pa-md-3"  cols="12" md="6" lg="3" sm="12">
                    <v-card class="mx-auto" outlined >
                        <div class="overline tab-name px-4 pt-3">Оплати</div>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-subtitle class="mb-0">
                                </v-list-item-subtitle>
                                <v-list-item-title class="headline mb-1"> <h3>{{payments}}</h3> </v-list-item-title>
                            </v-list-item-content>
                            <v-icon class="" size="60" color="primary">mdi-currency-usd  </v-icon>
                        </v-list-item>
                    </v-card>
                </v-col>

                <v-col class="pa-0 px-md-3" cols="12" md="6" lg="6">
                    <Users/>
                </v-col>
                <v-col class="pa-0 px-md-3" cols="12" md="6" lg="6">
                    <Payments/>
                </v-col>

            </v-row>
        </v-container>

    </div>
</template>

<script>
    import MainLayout from "../layouts/MainLayout";
    import Users from "./components/Dashboard/Users";
    import Payments from "./components/Dashboard/Payments";
    import {mapActions} from 'vuex'
    export default {
        name: "Dashboard",
        data() {
            return {
                users: 0,
                payments: 0,
                notifications: 0,
                feedbacks: 0,
                statisticsLoading: false
            }
        },
        components: {Users, MainLayout, Payments},
        computed: {},
        methods: {
            ...mapActions('dashboard', ['dashboard']),
            getDashboardStatistic()
            {
                this.statisticsLoading = true;
                this.dashboard()
                .then((resp) => {
                    this.users = resp.data.users
                    this.payments = resp.data.payments;
                    this.notifications = resp.data.notifications;
                    this.feedbacks = resp.data.feedbacks;
                    this.statisticsLoading = false;
                })
                .catch((error) => {
                    this.statisticsLoading = false;
                })
            }
        },
        mounted() {
            this.getDashboardStatistic()
        }
    }
</script>

<style scoped>
    .dashboard-content
    {
        height: calc(100vh - 70px);
        overflow-x: hidden;
        overflow-y: auto;
    }
    @media screen and (max-width: 960px) {
        .dashboard-content
        {
            height: calc(100vh - 56px);
        }
    }
</style>