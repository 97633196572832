<template>
    <div>
        <Loader v-if="loader"/>
        <v-app id="inspire">
            <div class="text-center">
                <v-snackbar :color="snackbarData.color" timeout="2000" :top="true" rounded="pill" v-model="snackbarData.snackbar" :multi-line="snackbarData.multiLine">
                    {{ snackbarData.text }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="white" text v-bind="attrs" @click="snackbarData.snackbar = false"><v-icon>mdi-close</v-icon></v-btn>
                    </template>
                </v-snackbar>
            </div>
            <v-main>
                <v-container class="fill-height" fluid>
                    <v-row align="center" justify="center">
                        <v-col cols="12" sm="8" md="4" lg="4" class="text-center pa-0">
                            <v-card class="elevation-12 mx-auto">
                                <v-img id="loginImage" class="white--text align-end grey darken-4" height="260px"  contain
                                       :src="require('../assets/image/logo.png')" size="auto">
                                </v-img>

                                <v-toolbar dark flat color="grey darken-4">
                                    <v-row>
                                        <v-col class="d-flex justify-space-around">
                                            <v-toolbar-title class="white--text ">ЖИТОМИР МОБАЙЛ</v-toolbar-title>
                                        </v-col>
                                    </v-row>
                                </v-toolbar>
                                <v-toolbar color="grey darken-4" dark flat>
                                    <v-row>
                                        <v-col class="d-flex justify-space-around">
                                            <v-toolbar-title>Панель адміністратора</v-toolbar-title>
                                        </v-col>
                                    </v-row>
                                </v-toolbar>
                                <v-card-text>
                                    <v-form v-model="isValid">
                                        <v-text-field v-model="loginData.email" autofocus background-color="transparent" :rules="emailRules" label="Email" name="login" prepend-icon="mdi-account" type="text"></v-text-field>
                                        <v-text-field v-model="loginData.password" :rules="passRules" id="password" label="Пароль" name="password" prepend-icon="mdi-lock" type="password"></v-text-field>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn @click.prevent="onClickLoginAdmin" color="white" text block :disabled="!isValid">Увійти</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-main>
        </v-app>

    </div>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex'
    import Vue from 'vue'
    import Loader from "../components/Loader";
    export default {
        name: "Login",
        data() {
            return {
                emailRules: [
                    value => !!value || 'Поле обов\'язкове для заповнення.',

                    value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Це не схоже на Email.'
                    },
                ],
                passRules: [
                    value => !!value || 'Поле обов\'язкове для заповнення.',
                    value => (value || '').length > 5 || 'Мінімум 6 символів',
                ],
                loginData: {
                    email: '',
                    password: ''
                },
                isValid: true,
                snackbarData: {
                    multiLine: true,
                    snackbar: false,
                    text: '',
                    color: ''
                }
            }
        },
        components: {
            Loader
        },
        computed: {
            ...mapState('auth', ['loader'])
        },
        methods: {
            ...mapActions('auth', ['login']),
            onClickLoginAdmin()
            {
                this.login(this.loginData)
                .then((resp) => {
                    this.snackbarData.snackbar = true;
                    this.snackbarData.text = 'Ласкаво просимо';
                    this.snackbarData.color = 'success';
                    setTimeout(() => {
                        this.clearSnackbarData()
                        this.$router.push({name: 'Dashboard'})
                    }, 2000)
                })
                .catch((error) => {
                    this.snackbarData.snackbar = true;
                    this.snackbarData.text = error.response.data.errors;
                    this.snackbarData.color = 'red';
                    setTimeout(() => {
                        this.clearSnackbarData()
                    }, 2000)
                })
            },
            clearSnackbarData()
            {
                this.snackbarData = {
                    snackbar: false,
                    text: '',
                    color: ''
                }
            }
        },
        created () {
            this.$vuetify.theme.dark = true
        },
        mounted() {
        }
    }
</script>

<style scoped>
    input:-internal-autofill-selected{
        background-color: transparent !important;
    }
   </style>