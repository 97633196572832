<template>
  <v-app>
      <transition name="fade" mode="out-in">
          <component :is="layout">
              <router-view/>
          </component>
      </transition>
      <v-snackbar v-model="message.isShow" :color="message.type" multi-line top right >
          {{ message.text }}
          <v-btn text icon @click="onClickHideMessage" >
              <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-snackbar>
  </v-app>
</template>

<script>
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'App',
    data: () => ({
        //
    }),
  components: {
      AuthLayout,
      MainLayout
  },
    computed:{
        layout() {
            return (this.$route.meta.layout || 'auth') + '-layout';
        },

        ...mapGetters('notifications', ['message'])
    },
    methods: {
      ...mapActions('notifications', ['hideMassage']),
        onClickHideMessage()
        {
            this.hideMassage()
        }
    }
};
</script>
<style scoped>
    .fade-enter, .fade-leave-to {
        opacity: 0;
        transform: translateX(2em);
    }
    .fade-enter-active, .fade-leave-active{
        transition: all .3s ease;
    }
    html,body {
        overflow: hidden !important;
    }
</style>
