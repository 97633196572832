const BASE_ULR = 'https://zhitomir.billing.city/api/enterprises/admin/v1';
import axios from "axios";
function initialState () {
    const feedback = [];
    const loader = false;
    return {
        feedback,
        loader
    }
}

const getters = {
    feedback(state)
    {
        return state.feedback
    }
};

const actions = {
    async getAllFeedback(ctx, page = 1)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/feedbacks?page=' + page,
                method: 'GET',
            })
                .then((response) => {
                    ctx.commit('setFeedback', response.data.feedbacks)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async getFeedbackHistory(ctx, id)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/feedbacks/' + id,
                method: 'GET'
            })
                .then((resp) => {
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async sendFeedbackAnswer(ctx, {id, data})
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/feedbacks/' + id,
                method: 'POST',
                data: data
            })
                .then((resp) => {
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

};

const mutations = {
    setFeedback(state, feedback)
    {
        state.feedback = feedback
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
