const BASE_ULR = 'https://zhitomir.billing.city/api/enterprises/admin/v1';
import axios from "axios";
function initialState () {
    const message = {
        isShow: false,
        text: '',
        type: 'info',
        timeout: 6000
    };
    return {
        message
    }
}

const getters = {
    message(state)
    {
        return state.message
    }
};

const actions = {
    async getAllNotifications(ctx, id)
        {
            return new Promise((resolve, reject) => {
                axios({
                          url: BASE_ULR + '/users/' + id + '/notifications',
                method: 'GET',
        })
        .then((response) => {
            ctx.commit('setNotifications', response.data.notifications)
            resolve(response)
        })
        .catch((error) => {
            reject(error)
        })
        })
    },

    async showMessage(ctx, message)
    {
        ctx.commit('updateMessage', {
            isShow: true,
            text: message.text,
            type: message.type,
            timeout: 6000
        })
    },

    async hideMessage(ctx)
    {
        ctx.commit('hideMessage')
    },

    async getSheduledNotifications(ctx, page = 1, searchField = '')
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR +  '/notifications?page=' + page + '&search=' + searchField + '&project=zhytomyr',
                method: 'GET'
            })
                .then((resp) => {
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async getSentNotifications(ctx)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/notifications/sent?project=zhytomyr',
                method: 'GET'
            })
                .then((resp) => {
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async create(ctx, data)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/notifications/create',
                method: 'POST',
                data: data,
            })
                .then((resp) => {
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async edit(ctx, data)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/notifications/edit',
                method: 'POST',
                data: data,
            })
                .then((resp) => {
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    async delete(ctx, id = 0)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/notifications/del/' + id,
                method: 'DELETE'
            })
                .then((resp) => {
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
};

const mutations = {
    setNotifications(state, notifications)
    {
        state.notifications = notifications
    },
    updateMessage(state, message)
    {
        state.message = message
    },
    hideMessage(state)
    {
        state.message.isShow = false
    }
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
