const BASE_ULR = 'https://zhitomir.billing.city/api/enterprises/admin/v1';
import axios from "axios";
function initialState () {

    return {

    }
}

const getters = {

};

const actions = {
    async dashboard(ctx)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/statistics/dashboard',
                method: 'GET',
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async newUsers(ctx)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/users/new',
                method: 'GET'
            })
                .then((resp) => {
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    async newPayments(ctx)
    {
        return new Promise((resolve, reject) => {
            axios({
                url: BASE_ULR + '/payments/new',
                method: 'GET'
            })
                .then((resp) => {
                    resolve(resp)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

};

const mutations = {

};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
