<template>
    <v-col class="mobile-sidebar px-0 py-0 pr-md-3 py-md-3" transition="slide-x-reverse-transition" cols=12 md=5 lg=4>
        <v-card height="100%" outlined class="py-2 user-card">
            <v-row align="end" v-if="user.id">
                <v-col align-self="start" cols="12">
                    <v-row>
                        <v-col cols=11 class="px-8 text-left pb-0">

                            <v-avatar color="primary" size="50" class="float-left mr-3">
                                <span class="white--text headline">Користувач</span>
                            </v-avatar>
                            <h4 class="mb-0">Користувач №{{user.id}}</h4>
                            <p class="mb-0 ">ID: {{user.id}}
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="px-8 text-left pb-0">
                            <p class="mb-0 mt-2"> <v-icon>mdi-email</v-icon> {{user.email}}</p>
                            <p class="mb-3 mt-1"> <v-icon>mdi-phone</v-icon> {{user.phone}}</p>
                        </v-col>
                    </v-row>
                    <v-btn color="white" icon absolute top right @click="onClickCloseButton"> <v-icon>mdi-close</v-icon> </v-btn>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <div class="user-content">
                <router-view>
                </router-view>
            </div>
            <v-bottom-navigation absolute color="primary" bottom="0" grow>
                <v-btn :to="{name:'UserAccounts'}">
                    <span>О/Р</span>
                    <v-icon>mdi-account-multiple</v-icon>
                </v-btn>

                <v-btn :to="{name:'UserNotifications'}">
                    <span>Повідомлення</span>
                    <v-icon>mdi-mail</v-icon>
                </v-btn>

                <v-btn :to="{name:'UserPayments'}">
                    <span>Оплати</span>
                    <v-icon>mdi-cash-multiple</v-icon>
                </v-btn>
            </v-bottom-navigation>
            <v-overlay :value="userLoading" color="white" :opacity="1" absolute>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-col>
</template>

<script>
    import {mapActions} from 'vuex'
    export default {
        props: ["id"],
        name: "User",
        data() {
            return {
                bottomNav: "accounts",
                userLoading: false,
                user: {
                    id: 0,
                    phone: "",
                    email: ""
                }
            }
        },
        components: {},
        computed: {
            avatarText:{
                get()  {
                        return  this.user.id;
                }
            }
        },
        methods: {
            ...mapActions('users', ['getAllUsers', 'getOneUser']),
            onClickCloseButton(){
                this.$store.commit('accounts/setAccounts', '')
                // this.$store.commit('users/setUser', '')
                this.$router.push('/users');
            },
            getUser()
            {
                // this.$store.commit('users/setUser', '')
                this.userLoading = true
                this.getOneUser(this.id)
                .then((resp) => {
                    this.user = resp.data.user
                    this.userLoading = false
                })
            }
        },
        watch:{
            id: function(){
                this.getUser()
            }
        },
        mounted() {
            this.getAllUsers();
            this.getUser()
        }
    }
</script>

<style scoped>
    .user-content
    {
        height: calc(100vh - 300px);
        overflow-y: auto;
    }
    .user-card
    {
        overflow: hidden;
    }
    @media screen and (max-width: 960px) {
        .user-content
        {
            height: calc(100vh - 300px);
        }
    }
</style>