<template>
    <v-card outlined>
        <v-card-title class="">
            <v-col class="py-2">
                <h5>Останні оплати</h5>
            </v-col>
        </v-card-title>
        <div class="dashboard-users-content">
            <v-list two-line subheader>
                <PaymentItem
                        v-for="payment in payments"
                        :key="payment.id"
                        :id="payment.id"
                        :amount="payment.amount"
                        :description="payment.description"
                        :time="payment.time"
                        :date="payment.date"
                />
            </v-list>
        </div>
        <v-overlay :value="paymentsLoading" color="white" :opacity="0.8" absolute>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script>
    import PaymentItem from '../Dashboard/PaymentItem'
    import {mapActions} from 'vuex'
    export default {
        name: "Payments",
        data() {
            return {
                payments: [],
                paymentsLoading: false
            }
        },
        components: {
            PaymentItem
        },
        computed: {},
        methods: {
            ...mapActions('dashboard', ['newPayments']),
            getPayments()
            {
                this.paymentsLoading = true
                this.newPayments()
                .then((resp) => {
                    this.payments = resp.data.payments
                    this.paymentsLoading = false
                })
                .catch((error) => {
                    this.paymentsLoading = false
                })
            }
        },
        mounted() {
            this.getPayments()
        }
    }
</script>

<style scoped>
    .dashboard-users-content
    {
        height: calc(100vh - 300px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    @media screen and (max-width: 1264px) {
        .dashboard-users-content
        {
            height: calc(100vh - 440px);
        }
    }
    @media screen and (max-width: 960px) {
        .dashboard-users-content
        {
            height: auto;

        }
    }
</style>