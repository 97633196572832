<template>
    <div>
        <div class="text-center">
            <v-overlay>
                <v-progress-circular color="blue" indeterminate :size="70" :width="7"></v-progress-circular>
            </v-overlay>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loader",
        data() {
            return {
                overlay: false,
            }
        },
        components: {},
        computed: {},
        methods: {},
        mounted() {
        }
    }
</script>

<style scoped>

</style>