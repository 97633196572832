<template>
    <v-card flat min-height="90%">
        <v-list class="pa-0 mt-3" min-height="20vh">
            <PaymentItem
                    v-for="payment in payments"
                    :key="payment.id"
                    :id="payment.id"
                    :amount="payment.amount"
                    :date="payment.date"
            />
            <v-overlay :value="paymentsLoading" color="black" :opacity="0.8" absolute z-index="2">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
            <v-overlay :value="payments.length < 1" :opacity="1" absolute z-index="1">
                <div class="text-center" style="width: 100%">
                    <h1><v-icon color="grey lighten-2" size="50">mdi-currency-usd-off</v-icon></h1>
                    <p class="grey--text text--lighten-1">Оплат немає</p>
                </div>
            </v-overlay>
        </v-list>
    </v-card>
</template>

<script>
    import PaymentItem from "./PaymentItem";
    import {mapActions} from 'vuex'
    export default {
        props: ["id"],
        name: "UserPayments",
        data() {
            return {
                paymentsLoading: false,
                payments: []
            }
        },
        components: {
            PaymentItem
        },
        computed: {
        },
        watch: {
            $route: function () {
                this.getPayments()
            }
        },
        methods: {
            ...mapActions('payments', ['getAllPayments']),
            getPayments()
            {
                this.paymentsLoading = true
                this.getAllPayments(this.id)
                    .then((resp) => {
                        this.payments = resp.data.payments
                        this.paymentsLoading = false
                    })
                    .catch((error) => {
                        this.payments = []
                        this.paymentsLoading = false
                    })
            }
        },
        mounted() {
            this.getPayments()
        }
    }
</script>

<style scoped>

</style>