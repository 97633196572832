<template>
    <div>
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: "AuthLayout",
        data() {
            return {}
        },
        components: {},
        computed: {},
        methods: {},
        mounted() {
        }
    }
</script>

<style scoped>

</style>