<template>
    <v-app id="keep">
        <v-navigation-drawer v-model="drawer" app clipped>
            <v-list-item two-line>
                <v-list-item-avatar color="primary">
                    <span class="white--text headline">A</span>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{admin.name}}</v-list-item-title>
                    <v-list-item-subtitle>{{admin.email}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list dense>
                <v-list-item v-for="(item, i) in items" :key="i" :to="{name: item.routeName}">
                    <v-list-item-action>
                        <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{item.text}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <div class="px-2 pb-2">
                    <v-btn @click="onClickLogout" text block >
                        <v-icon left>mdi-logout</v-icon>Вийти
                    </v-btn>
                </div>
            </template>
        </v-navigation-drawer>

        <v-app-bar app clipped-left>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>ЖИТОМИР МОБАЙЛ</v-toolbar-title>
        </v-app-bar>

        <v-main>
            <transition name="fade" mode="out-in">
                <router-view/>
            </transition>
        </v-main>
    </v-app>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    export default {
        name: "MainLayout",
        data() {
            return {
                drawer: null,
                items: [
                    { icon: 'mdi-home', text: 'Головна', routeName: 'Dashboard'},
                    { icon: 'mdi-account-multiple', text: 'Користувачі', routeName: 'Users'},
                    { icon: 'mdi-email-multiple', text: 'Повідомлення', routeName: 'Notifications'},
                    { icon: 'mdi-chart-bar', text: 'Статистика', routeName: 'Statistics'},
                    { icon: 'mdi-frequently-asked-questions', text: 'Зворотній зв\'язок', routeName: 'Feedbacks'},
                ]
            }
        },
        components: {},
        computed: {
            ...mapGetters('auth', ['admin'])
        },
        methods: {
            ...mapActions('auth', ['logout']),
            onClickLogout()
            {
                this.logout()
                .then(() => {
                    this.$router.push({name: 'Login'})
                })
            }
        },
        created () {
            this.$vuetify.theme.dark = true
        },
        mounted() {
        }
    }
</script>

<style scoped>
    .fade-enter, .fade-leave-to {
        opacity: 0;
        transform: translateX(2em);
    }
    .fade-enter-active, .fade-leave-active{
        transition: all .3s ease;
    }
    .fill-height-main{
        align-items: start !important;
    }
</style>