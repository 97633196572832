<template>
    <v-list-item class="px-6">
        <v-list-item-content class="">
            <v-list-item-title class="mt-1">Користувач</v-list-item-title>
            <v-list-item-subtitle class="mt-1">{{OS_RAH}}</v-list-item-subtitle>
            <v-list-item-subtitle class="mt-1">{{NVUL}},{{BUD}},{{KVAR}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-group class="mt-15">
            <v-chip small class="mt-1" :color="SAL_POCH > 0 ? 'red' : 'green'" text-color="white">
                {{Math.abs(SAL_POCH)}} грн
            </v-chip>
        </v-list-item-group>
    </v-list-item>
</template>

<script>
    export default {
        props: [ "OS_RAH","NVUL","BUD","KVAR","SAL_POCH"],
        name: "AccountItem",
        data() {
            return {}
        },
        components: {},
        computed: {},
        methods: {},
        mounted() {
        }
    }
</script>

<style scoped>

</style>