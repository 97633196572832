<template>
    <v-list-item class="px-6">
        <v-list-item-content class="">
            <v-list-item-title><v-icon class="mr-1" color="green">mdi-currency-usd-circle</v-icon> {{amount}} грн</v-list-item-title>
            <v-list-item-subtitle class="mt-1"></v-list-item-subtitle>
            <small class="grey--text text--lighten-1">{{description}}</small>
        </v-list-item-content>

        <v-list-item-group>
            <v-row class="text-right">
                <v-col cols="12 py-0">
                    <h5 class="font-weight-regular">{{time}}</h5>
                </v-col>
                <v-col cols="12 py-0">
                    <small class="grey--text text--lighten-1">{{date}}</small>
                </v-col>
            </v-row>

        </v-list-item-group>
    </v-list-item>
</template>

<script>
    export default {
        props: ['id', 'amount', 'date', 'time', 'description'],
        name: "PaymentItem",
        data() {
            return {}
        },
        components: {},
        computed: {},
        methods: {},
        mounted() {
        }
    }
</script>

<style scoped>

</style>