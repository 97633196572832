<template>
    <div class="chart-wrapper">
        <line-chart :chart-data="chartdata" :height="200" :options="options"></line-chart>
    </div>
</template>

<script>
    Array.prototype.max = function () {
        return Math.max.apply(null, this)
    };
    Array.prototype.min = function () {
        return Math.min.apply(null, this)
    };
    import LineChart from "./LineChart";
    export default {
        props: ['data', 'labels'],
        name: "Chart",
        data() {
            return {
                chartdata: {},
                options: {}
            }
        },
        components: {
            LineChart
        },
        computed: {},
        methods: {
            fillData ()
            {
                this.chartdata = {
                    labels: this.labels,
                    datasets: [
                        {
                            label: 'Кількість',
                            borderColor: "#1976d2",
                            data: this.data,
                            fill: false
                        }
                    ]
                };
                this.options = {
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: false
                };
            }
        },
        watch: {
            data: function (value) {
                this.fillData()
            }
        },
        mounted() {
            this.fillData()
        }
    }
</script>

<style scoped>
    .chart-wrapper {
        padding: 20px;
        max-width: 100%;
    }
</style>