<template>
    <v-list-item color="primary" :to="'/users/'+id" >
        <v-list-item-avatar color="primary">
            <span class="white--text headline">Користувач</span>
        </v-list-item-avatar>

        <v-list-item-content>
            <v-list-item-title>Користувач № {{id}}</v-list-item-title>
            <v-list-item-subtitle>{{phone}}</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        props: ["id", "phone"],
        name: "UserItem",
        data() {
            return {}
        },
        components: {},
        computed: {
            avatarText(){

            }
        },
        methods: {
            ...mapActions('accounts', ['getAccounts']),
            ...mapActions('users', ['getOneUser']),
            onClickItem()
            {
                this.$emit('show');
            },
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>