<template>
    <v-card flat min-height="90%">
        <v-list class="pa-0 mt-3" min-height="20vh">
            <NotificationItem
                    v-for="notification in notifications"
                    :key="notification.id"
                    :title="notification.title"
                    :text="notification.text"
                    :date="notification.date"
            />
            <v-overlay :value="notificationsLoading" color="black" :opacity="0.8" absolute z-index="2">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
            <v-overlay :value="notifications.length < 1" :opacity="1" absolute z-index="1">
                <div class="text-center" style="width: 100%">
                    <h1><v-icon color="grey lighten-2" size="50">mdi-email-off</v-icon></h1>
                    <p class="grey--text text--lighten-1">Повідомлень немає</p>
                </div>
            </v-overlay>
        </v-list>
    </v-card>
</template>

<script>
    import NotificationItem from "./NotificationItem";
    import {mapActions} from 'vuex'
    export default {
        props: ['id','title', 'text', 'date'],
        name: "UserNotifications",
        data() {
            return {
                items: {},
                notificationsLoading: false,
                notifications: []
            }
        },
        components: {NotificationItem},
        computed: {},
        methods: {
            ...mapActions('notifications', ['getAllNotifications']),
            getNotifications()
            {
                this.notificationsLoading = true
                this.getAllNotifications(this.id)
                .then((resp) => {
                    this.notifications = resp.data.notifications
                    this.notificationsLoading = false
                })
                .catch((error) => {
                    this.notifications = []
                    this.notificationsLoading = false
                })
            }
        },
        watch: {
            $route: function () {
                this.getNotifications()
            }
        },
        mounted() {
            this.getNotifications()
        }
    }
</script>

<style scoped>

</style>