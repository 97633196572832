<template>
    <v-card class="mb-3 pa-0" flat>
        <v-divider class="mb-2"></v-divider>
        <v-card-title class="px-3">
            <v-row>
                <v-col cols="8" class="py-0 px-0">
                    <h5 class="mb-0">{{title}}</h5>
                </v-col>
                <v-col cols="4" class="py-0 px-0">
                    <v-chip class="float-right" small :color="audienceColor" text-color="white">
                        {{audienceLabel}}
                    </v-chip>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="px-0 pb-0 pb-md-3">
            <v-row>
                <v-col cols="12" lg="5" class="py-0 ">
                    {{text}}
                </v-col>
                <v-col v-if="this.audience == 1" cols="12" lg="7" class="py-0 pr-0 text-center">
                    <v-row>
                        <v-col cols="3" class="pt-5">
                            <h2>{{successCount}}</h2>
                            <small>Вдало</small>
                        </v-col>
                        <v-col cols="3" class="pt-5">
                            <h2>{{failedCount}}</h2>
                            <small>Невдало</small>
                        </v-col>
                        <v-col cols="3" class="pt-5">
                            <h2>{{devicesCount}}</h2>
                            <small>Всього пристроїв</small>
                        </v-col>
                        <v-col cols="3">
                            <v-progress-circular
                                    :rotate="270"
                                    :size="50"
                                    :width="4"
                                    :value="inProcessStatus"
                                    :color="inProcessStatus < 100 ? 'blue' :'green'"
                            >
                                {{inProcessStatus}}%
                            </v-progress-circular>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="px-0">
            <v-icon class="mr-2">mdi-calendar</v-icon> {{date}}
            <v-icon class="mx-2">mdi-clock</v-icon> {{time}}
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        props: [
            'id',
            'title',
            'text',
            'audience',
            'time',
            'date',
            'success-count',
            'failed-count',
            'curr-token',
            'count-token',
            'status',
            'devices-count'
        ],
        name: "PushSentItem",
        data() {
            return {}
        },
        components: {},
        computed: {
            audienceLabel()
            {
                const labels = ['Всі', 'Боржники'];
                return labels[this.audience]
            },

            audienceColor()
            {
                const colors = ['green', 'red'];
                return colors[this.audience]
            },

            inProcessStatus()
            {
                const sent = ((this.successCount+this.failedCount)/this.devicesCount);
                const processed = (this.currToken/this.countToken);
                const status = (sent*processed*100).toFixed(0) > 100 ? 100 : (sent*processed*100).toFixed(0)
                return status
                console.log(typeof(status) )
            }
        },
        methods: {
            onClickEdit()
            {
                this.$emit('edit', {
                    id: this.id,
                    title: this.title,
                    text: this.text,
                    audience: this.audience,
                    date: this.date,
                    time: this.time,
                })
            },

            onClickDelete()
            {
                this.$emit('delete', {title: this.title, id: this.id})
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>