<template>
    <v-container>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline mt-3">{{title}}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" class="my-0 pa-0">
                                    <v-select v-model="push.audience" :items="audienceItems" label="Аудиторія"></v-select>
                                </v-col>
                                <v-col cols="12" class="my-0 pa-0">
                                    <v-text-field v-model="push.title" label="Тема"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="my-0 pa-0">
                                    <v-textarea required v-model="push.text" label="Текст" auto-grow
                                    ></v-textarea>
                                </v-col>

                                <v-col v-if="push.audience == 1" cols="12" class="my-0 pa-0">
                                    <v-alert type="info"  border="top" colored-border elevation="2">
                                        <h5> інформація про користувача</h5 >
                                        <v-row>
                                            <v-col cols="6" v-for="field in fieldsItems" :key="field.value">
                                                <small><p class="mb-0"><span class="primary--text">{{field.value}}</span>{{field.name}}</p></small>
                                            </v-col>
                                        </v-row>
                                    </v-alert>
                                </v-col>

                                <v-col cols="12" class="my-0 pa-0">
                                    <v-dialog ref="dialog" v-model="dateModal" :return-value.sync="push.date" persistent width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="push.date" label="Дата" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="push.date" scrollable >
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="dateModal = false">Скасувати</v-btn>
                                            <v-btn text color="primary" @click="$refs.dialog.save(push.date)">OK</v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col cols="12" class="my-0 pa-0">
                                    <v-dialog ref="dialogTime" v-model="timeModal" :return-value.sync="push.time" persistent width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="push.time" label="Час" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-time-picker v-if="timeModal" v-model="push.time" format="24hr" full-width>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="timeModal = false">Скасувати</v-btn>
                                            <v-btn text color="primary" @click="$refs.dialogTime.save(push.time)">OK</v-btn>
                                        </v-time-picker>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="pr-5 pb-5">
                        <v-spacer></v-spacer>
                        <v-btn color="grey darken-1" class="px-3" text @click="dialog = false">Скасувати</v-btn>
                        <v-btn v-if="!isEdit" @click="createPush" color="green accent-4" class="px-5" dark>Створити</v-btn>
                        <v-btn v-if="isEdit" @click="savePush" color="green accent-4" class="px-5" dark>Зберегти</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
    import {mapActions} from 'vuex'
    export default {
        name: "PushModal",
        data() {
            return {
                timeModal: false,
                dateModal: false,
                dialog: false,
                isEdit: false,
                push: {
                    title: '',
                    audience: '',
                    text: '',
                    date: '',
                    time: '',
                }
            }
        },
        components: {},
        computed: {
            title()
            {
                return this.isEdit ? 'Редагувати повідомлення' : 'Створити повідомлення'
            },

            audienceItems()
            {
                return [
                    {text: 'Всі', value: 0},
                    {text: 'Боржники', value: 1},
                ]
            },

            fieldsItems()
            {
                return [
                    {
                        name: ' - особовий рахунок',
                        value: '{ls}'
                    },
                    {
                        name: ' - борг',
                        value: '{debt}'
                    },
                    {
                        name: ' - ПІБ',
                        value: '{name}'
                    },
                    {
                        name: ' - дата боргу',
                        value: '{date}'
                    },
                ]
            }
        },
        methods: {
            ...mapActions('notifications', ['create', 'edit']),
            show(isEdit, push = {
                title: '',
                text: '',
                audience: '',
                date: '',
                time: '',
            })
            {
                this.isEdit = isEdit;
                this.push = push;
                this.dialog = true
            },

            showAlert(text, color)
            {
                this.$emit('alert', text, color)
            },

            dateToCron()
            {
                const time = this.push.time.split(':');
                const date = this.push.date.split('-');
                const expression = time[1] + ' ' + time[0] + ' ' + date[2] + ' ' + date[1] + ' *';
                return expression
            },

            validate()
            {
                let error = ""
                if(this.push.time === "")
                    error = "Не вказано час"
                if(this.push.date === "")
                    error = "Не вказана дата"
                if(this.push.text.length < 3 || this.push.text.length > 25000)
                    error = "Кількість символів в полі Текст має бути від 3 до 25000"
                if(this.push.title.length < 3 || this.push.title.length > 255)
                    error = "Кількість символів в полі Тема має бути від 3 до 255"
                if(this.push.audience === "")
                    error = "Не вказана аудиторія"
                if(error != ""){
                    this.showAlert(error, 'error')
                    return false;
                }
                return true;
            },

            createPush()
            {
                if (!this.validate())
                    return false;
                this.create({
                    title: this.push.title,
                    text: this.push.text,
                    audience: this.push.audience,
                    date: this.push.date,
                    time: this.push.time,
                    project: 'zhytomyr',
                    expression: this.dateToCron()
                })
                .then((resp) => {
                    this.$emit('update');
                    this.showAlert('Успішно додано', 'success');
                    console.log(resp.data);
                    this.dialog = false
                })
                .catch((error) => {
                    console.log(error.response.data)
                })
            },

            savePush()
            {
                if (!this.validate())
                    return false;
                this.edit({
                    id: this.push.id,
                    title: this.push.title,
                    text: this.push.text,
                    audience: this.push.audience,
                    date: this.push.date,
                    time: this.push.time,
                    project: 'zhytomyr',
                    expression: this.dateToCron()
                })
                .then(() => {
                    this.$emit('update');
                    this.showAlert('Успішно збережений', 'success');
                    this.dialog = false
                })
                .catch((error) => {
                    console.log(error.response.data)
                })
            }

        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>